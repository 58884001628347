.nav{
    display: block;
    color: white;
    padding-top: 1%;
}
.nav__container{
    width: 30%;
    display: block;
    justify-content: space-between;
}
.nav__item a{
    color: white;
    text-decoration: none;
    font-weight: 100;
    font-size: 25px;
    font-stretch: expanded;
    padding-left: 10px;
}
.nav__item a.active{
    color: white;
    font-weight:400;
}
