.container {
    width: 80%;
    margin: 0 auto;
  }
  
  .h {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form {
    margin-bottom: 20px;
  }
  
  .inputGroup {
    margin-bottom: 10px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    margin-top: 5px;
  }
  /* Rectangle 1 */



  .button {
    padding: 8px 16px; /* Уменьшим отступы для кнопок */
    min-width: 80px; /* Минимальная ширина кнопки */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px; /* Уменьшим размер шрифта для кнопок */
    margin-right: 5px; /* Уменьшим отступ справа для кнопок */
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
  }
  
  .image {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 5px;
  }

  .inputError {
    border: 1px solid red;
}

.h {
  position: relative;
}

.h::before {
  content: "!";
  position: absolute;
  top: 50%;
  right: 5px; 
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #007bff; 
  border-radius: 50%; 
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help; 
  z-index: 1; 
}

.h::after {
  content: attr(data-tooltip);
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: normal; 
  z-index: 1; 
  display: none;
}

.h:hover::after {
  display: block; 
}

.table-container {
  max-height: 500px; /* Задайте максимальную высоту контейнера таблицы */
  overflow-y: auto; /* Добавьте вертикальную прокрутку при необходимости */
}


