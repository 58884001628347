.text{
    color: white;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
}
.header {
    display: flex;
    align-items: center;
}

.image {
    width: 70px;
    height: auto;
    margin-right: 10px; 
    margin-left: 10px; 
}

.title {
    font-size: 34px;
    margin: 0;
}
.title2 {
    font-size: 12px;
    color: #555;
    margin-top: 50px;
    margin-left: -102px;
}
