.container{
    height: 100%;
}
.block__text{
    align-items: center;
}
.h{
    text-align: center;
    font-size: 45px;
    font-weight: 200;
    margin-bottom: 50px;
}
.text{
    height: 100%;
    width: 100%;
    text-align: center;
    font-weight: 200;
    font-style: normal;
    font-size: 21px;
    /*line-height: 36px;*/
}
.container h1{
    color: white;
}

.button {
    background-color: #293754;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px;
    border-radius: 30px
}

.added {
  padding: 10px 20px;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  margin: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 100px;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
    color: #444;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }

  .pageNumbers {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 0;
  }
  
  .pageNumbers li {
    margin: 0 5px;
  }
  
  .pageNumbers button {
    border: 2px solid #bbb;
    background-color: transparent;
    color: #555;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; 
    border-radius: 50%; 
  }
  
  .pageNumbers button:hover {
    background-color: #ddd; 
    color: #333; 
  }
  
  .active button {
    background-color: #bbb; 
    color: #333; 
    border-color: #bbb; 
  }
  


  