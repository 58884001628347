.formControl.error input,
.formControl.error textarea
{
    border: solid red 3px;
}

.formControl.error span
{
    background-color: #841010;
    border: solid red 2px;
    padding: 2px;
    color: red;
    width: 200px;
    text-align: center;
    margin-left: 20px;
}
.wind{
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../../Icons/back.png");
    background-size: 90%;
    width: 100%;
    height: 100vh;
}
.container{
    position: absolute;
    left: 34%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 27%;
    height: 100vh;
}
.container__h{
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: 200;
    margin-bottom: 95px;
}

.container__form{
    width: 100%;
    display: block;
}
.block__input{
    margin-bottom: 20px;
}
.form__span{
    color: white;
    font-size: 17px;
    padding-left: 10px;
}
.form__input{
    height: 40px;
    width: 100%;
    margin-bottom: 3px;
    border-radius: 10px;
    margin-top: 3px;
}

.block__exit{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}
.exit{
    width: 60%;
    background-color: #6DA6E9;
    color: white;
    font-size: 19px;
    font-weight: 700;
    border-radius: 10px;
    padding: 8px 0;
    margin-top: 45px;
    box-shadow: 0 0 10px 7px rgba(130, 213, 239, 0.3);
}

