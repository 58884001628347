.table {
  width: 100%;
  border: 2px solid #fff;
}

.table thead tr {
  border-bottom: 2px solid #fff;
  height: 3rem;
  font-size: 1.5rem;
}

.table tr {
  border: 1px solid #fff;
  height: 2.5rem;
  font-size: 1rem;
}

td {
  color: black; 
}

.num {
  width: 10%;
}
.idx {
  width: 15%;
}
.city {
  width: 20%;
}
.street {
  width: 25%;
}
.price {
  width: 15%;
}
.square {
  width: 10%;
}
