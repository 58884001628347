.container{
    height: 100%;
}
.block__text{
    align-items: center;
}
.h{
    text-align: center;
    font-size: 45px;
    font-weight: 200;
    margin-bottom: 50px;
}
.text{
    height: 100%;
    width: 100%;
    text-align: center;
    font-weight: 200;
    font-style: normal;
    font-size: 21px;
    /*line-height: 36px;*/
}
.container h1{
    color: white;
}