.text {
    margin: 10px;
}

button {
    background-color: #283854;
}
.form__span {
    color: black; 
}
.form__input {
    color: gray;
}