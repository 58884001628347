.app-wrapper{
    /* background-image: url("./components/Icons/back.png"); */
    background-size: 100% ;
    height: 100vh ;
    background-color: #293754;
}
.header{
    height: 8%;
}
.app__container{
    display: flex;
    height: 92%;
}
.app__navbar{
    width: 15%;
    height: 100%;
    background: #293754;
    
}
.app__content{
    width: 85%;
    height: 100%;
    background: #cdcdcdb3;
}

p{
    color: white;
}